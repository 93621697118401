import FirebaseToRedux from '../firebase/FirebaseToRedux';
import { connect } from 'react-redux'
import { setPosts,setPost} from '../actions'

const mapStateToProps = state => {
  return {
      query:state.query,
      postId:state.postId
  };
};
const mapDispatchToProps = dispatch => {
  return {
    setPosts: value => {
      dispatch(setPosts(value));
    },
    setPost: value => {
      dispatch(setPost(value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FirebaseToRedux)
