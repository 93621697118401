import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paper : {
    width:'100%',
    maxWidth: '1000px',
    marginTop: theme.spacing(2),
    '& > *': {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2)
    },
  }
}))

const SimplePaper = (props) => {
  const classes = useStyles();
  return(
    <Paper className={classes.paper}>
      {props.children}
    </Paper>
  );
}

export default SimplePaper;
