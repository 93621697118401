import React from 'react';
import Page from '../templates/Page'
import PostEditor from '../organisms/PostEditor';
import Viewer from '../organisms/Viewer'
import FileUploader from '../organisms/FileUploader'
import PostUpdateButton from '../organisms/PostUpdateButton'

const EditPage = (props) => {
  const postId = props.match.params.id;
  const post = props.post ? props.post : {}
  props.setPostId(postId);
  return(
    <Page>
        <PostEditor
          postId={postId}
          post={post}
          setPost={props.setPost}
        />
        <div>
          <FileUploader
            postId={postId}
            post={post}
            setPost={props.setPost}
            setFile={props.setFile}
            file={props.file}
          />
          <PostUpdateButton
            postId={postId}
            post={post}
            file={props.file}
          />
        </div>
        <Viewer
          post={post}
        />
    </Page>
  )
}

export default EditPage;
