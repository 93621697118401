import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {setPost} from '../../firebase/firestore'
import {setPostImage} from '../../firebase/storage'

const useStyles = makeStyles(theme => ({
  button:{
    margin: theme.spacing(2),
  }
}));

const PostUpdateButton = (props) => {
  const classes = useStyles();
  const postId = props.postId ? props.postId : '';
  const post = props.post ? props.post : {};
  const handleClick = () => {
    setPost(postId,post);
    setPostImage(postId,props.file)
  }
  return (
      <Button
        className={classes.button}
        onClick={() => handleClick()}
        variant="contained" color="primary">
        記事を更新する
      </Button>
  );
}

export default PostUpdateButton;
