import 'firebase';
import { storageRef } from './index'
import { updatePost } from './firestore'

export const setPostImage = async (id,file) => {
  const path = 'postImages/' + id;
  const imagesRef = storageRef.child(path);
  console.log(file);
  await imagesRef.put(file).then((snapshot) => {
  });
  imagesRef.getDownloadURL().then((url) => {
    updatePost(id,{photo:url});
  })
};
