import { combineReducers } from "redux"
import posts from './posts'
import post from './post'
import postId from './postId'
import file from './file'

export default combineReducers({
  posts,
  post,
  postId,
  file
})
