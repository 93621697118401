import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom'
import SimpleAppBar from './components/organisms/SimpleAppBar'
import FirebaseToRedux from './containers/FirebaseToRedux'
import HomePage from './components/pages/HomePage'
import EditPage from './containers/EditPage'
import { GlobalStyle } from './GlobalStyle';
import { StylesProvider } from '@material-ui/styles';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
const theme = createMuiTheme({
  typography: {
      fontFamily: [
        'Noto Sans',
        'sans-serif',
      ].join(','),
    },
});

function App() {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <FirebaseToRedux/>
        <GlobalStyle/>
        <SimpleAppBar title=" J.M.Tools"/>
        <BrowserRouter>
          <Route exact path='/' component={HomePage} />
          <Route exact path='/edit/post/:id' component={EditPage} />
        </BrowserRouter>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default App;
