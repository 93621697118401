import * as firebase from 'firebase';
import { twitterProvider } from './index.js'
import { googleProvider } from './index.js'

export const twitterLogin = () => {
  firebase.auth().signInWithRedirect(twitterProvider);
}
export const googleLogin = () => {
  firebase.auth().signInWithRedirect(googleProvider);
}

export const logOut =() => {
  firebase.auth().signOut().then(function() {
    // Sign-out successful.
  }).catch(function(error) {
    // An error happened.
  });
}
