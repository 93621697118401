export const firebaseConfig = {
  apiKey: "AIzaSyDZ9lVTvkiqHSHru8HiaBm5RJuNLHTxBB4",
  authDomain: "jmc-corp.firebaseapp.com",
  databaseURL: "https://jmc-corp.firebaseio.com",
  projectId: "jmc-corp",
  storageBucket: "jmc-corp.appspot.com",
  messagingSenderId: "501717399345",
  appId: "1:501717399345:web:911d3090668dae3b8f6210",
  measurementId: "G-DFGSP8SBG5"
};
