const posts = (state = null, action) => {
  switch (action.type) {
    case 'SET_POSTS':
      var posts = action.value.slice();
      return posts;
    default:
      return state
  }
}

export default posts;
