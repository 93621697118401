import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import SimplePaper from '../molecules/SimplePaper'
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(theme => ({
  root: {
    width:'100%',
    maxWidth:'1000px',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '95%',
    },
  },
  button:{
    marginTop: theme.spacing(2),
  }
}));

const PostEditor = (props) => {
  const classes = useStyles();
  const post = props.post ? props.post : {};
  const title = post.title ? post.title : '';
  const tags = post.tags ? post.tags : [];
  const content = post.content ? post.content : '';
  const [tag,setTag] = React.useState('');
  const onValueChange = (type,value) => {
    var newData = Object.assign({},post);
    newData[type] = value;
    props.setPost(newData);
  }
  const handleDelete  = (i) => {
    var newTags = tags.slice();
    newTags.splice(i,1);
    var newData = Object.assign({},post);
    newData['tags'] = newTags;
    props.setPost(newData);
  }
  const renderTag = (i) => {
    return(<Chip key={i} label={tags[i]} onDelete={() => handleDelete(i)}/>
    )
  }
  const addTag = (tag) => {
    if(tag !== ''){
      var newTags = tags.slice();
      newTags.push(tag);
      var newData = Object.assign({},post);
      newData['tags'] = newTags;
      props.setPost(newData);
    }
  }
  const handleKeyDown = e => {
    if(e.keyCode === 13){
      addTag(e.target.value);
      e.target.value = setTag('');
    }
  };
  const chips = [];
  for(var i = 0; i < tags.length; i++){
    chips.push(renderTag(i));
  }
  return (
    <div className={classes.root} >
      <SimplePaper>
        <TextField
          id="title"
          label="Title"
          value={title}
          onChange={(e) => {
            onValueChange('title',e.target.value);
          }}/>
        <TextField
          id="tag"
          label="Add tag"
          value={tag}
          onChange={
            (e)  => setTag(e.target.value)
          }
          onKeyDown={handleKeyDown}
        />
        <div>tags : {chips}</div>
        <TextField
          id="content"
          label="Content"
          multiline
          rows="16"
          value={content}
          onChange={(e) =>
            onValueChange('content',e.target.value)
          }
        />
      </SimplePaper>
    </div>
  );
}

export default PostEditor;
