import React from 'react';
import MdContent from '../molecules/MdContent'
import SimplePaper from '../molecules/SimplePaper'

const Viewer = (props) => {
  const post = props.post ? props.post : {title:'',tags:[],content:''};
  const title = post.title;
  const tags = post.tags;
  const content = post.content;
  const photo = post.photo;
  return(
    <SimplePaper>
      <MdContent content={"# " + title}/>
      <img art="post main" src={photo}/>
      <MdContent content={content}/>
    </SimplePaper>
  )
}

export default Viewer;
