const post = (state = null, action) => {
  switch (action.type) {
    case 'SET_POST':
      var post = Object.assign({},action.value)
      return post;
    default:
      return state
  }
}

export default post;
