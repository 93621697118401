const file = (state = null, action) => {
  switch (action.type) {
    case 'SET_FILE':
      return action.value;
    default:
      return state
  }
}

export default file;
