import React from 'react'
import unified from 'unified'
import parse from 'remark-parse'
import remark2react from 'remark-react'


const MdContent = (props) => {
  const content = props.content;
  return (
    <div id="preview">
       {
         unified()
           .use(parse)
           .use(remark2react)
           .processSync(content).contents
         }
    </div>
  );
}

export default MdContent;
