import 'firebase';
import { firestore } from './index'

export const setPost = async (postId,data) => {
  return firestore.collection('posts').doc(postId).set(data);
}

export const updatePost = async (postId,data) => {
  return firestore.collection('posts').doc(postId).update(data);
}
