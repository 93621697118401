import React from 'react';
import styled from 'styled-components';

const Page = (props) => {
  return(
    <Div>
      {props.children}
    </Div>
  )
}

const Div = styled.div`
  margin:56px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width:767px){
    margin:64px 0;
  }
`
export default Page;
