import firebase from 'firebase/app';
import "firebase/firestore";
import "firebase/auth";
import 'firebase/functions'
import 'firebase/storage'
import { firebaseConfig } from './config.js';

firebase.initializeApp(firebaseConfig);
export const firestore = firebase.firestore();
export const functions = firebase.functions();
export const storageRef = firebase.storage().ref();
export const twitterProvider = new firebase.auth.TwitterAuthProvider();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const analytics = firebase.analytics();
