import React from 'react';
import Page from '../templates/Page'
import SimplePaper from '../molecules/SimplePaper'

const HomePage = (props) => {
  return(
    <Page>
      <SimplePaper>
        テスト
      </SimplePaper>
    </Page>
  )
}

export default HomePage;
