import EditPage from '../components/pages/EditPage';
import { connect } from 'react-redux'
import { setPostId,setPost,setFile } from '../actions'

const mapStateToProps = state => {
  return {
      post:state.post,
      postId:state.postId,
      file:state.file
  };
};
const mapDispatchToProps = dispatch => {
  return {
    setPostId: value => {
      dispatch(setPostId(value));
    },
    setPost: value => {
      dispatch(setPost(value));
    },
    setFile: value => {
      dispatch(setFile(value));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPage)
