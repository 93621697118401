import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
const createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL;

const useStyles = makeStyles(theme => ({
  input: {
    opacity:0,
    appearance: 'none',
    position: 'absolute'
  },
}));

const FileUploader = (props) => {
  const classes = useStyles();
  const post = props.post ? props.post : {};
  const onFileChange = (e) => {
    var newData = Object.assign({},post);
    var file = e.target.files[0];
    console.log(file);
    if(file != null){
      var image_url = createObjectURL(e.target.files[0]);
      newData['photo'] = image_url;
      props.setPost(newData);
      props.setFile(file);
    }
  }
  return (
    <span>
      <Button
        variant="contained"
        color="default"
        className={classes.button}
        startIcon={<CloudUploadIcon />}
      >
        サムネイルをアップロード
        <input
          type="file"
          className={classes.input}
          onChange={(e) => {
            onFileChange(e);
          }}
        />
      </Button>
    </span>
  );
}

export default FileUploader;
