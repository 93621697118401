export const setPosts = value => {
  return {type:'SET_POSTS',value:value}
}
export const setPost = value => {
  return {type:'SET_POST',value:value}
}
export const setPostId = value => {
  return {type:'SET_POST_ID',value:value}
}
export const setFile = value => {
  return {type:'SET_FILE',value:value}
}
