import { Component } from 'react';
import 'firebase';
import { firestore } from './index';

class FirebaseToRedux extends Component{
  observePosts(query){
    const self = this;
    firestore.collection('posts')
    .onSnapshot((querySnapshot) => {
      const posts = [];
      querySnapshot.forEach((doc) => {
        posts.push({
          id:doc.id,
          data:doc.data()
        });
        self.props.setPosts(posts);
      });
    })
  }
  observePost(postId){
    const self = this;
    firestore.collection('posts').doc(postId)
    .onSnapshot((doc) => {
      self.props.setPost(doc.data());
    }, err => {
      console.log(`Encountered error: ${err}`);
    });
  }
  componentDidMount(){

  }
  render(){
    const postId = this.props.postId ? this.props.postId : '0';
    const query = this.props.query ? this.props.query : {};
    this.observePost(postId);
    this.observePosts(query);
    return(null);
  };
};

export default FirebaseToRedux;
